import { DataTableHeader } from 'vuetify';

import { consoleI18n } from '@ax/console-i18n';

import { DeviceTableRowBase, ZoneDeviceTableRow } from '../models/device-table';

export const defaultDevicesTableFooter = {
  'items-per-page-options': [10, 25, 50, 100, 500],
  'items-per-page-text': consoleI18n.t('devices.labels.devicesPerPage'),
};

interface DevicesTableHeaderBase extends DataTableHeader<DeviceTableRowBase> {
  value: keyof DeviceTableRowBase;
}

const baseDevicesTableHeaders: DevicesTableHeaderBase[] = [
  {
    text: consoleI18n.t('general.labels.deviceName'),
    divider: true,
    sortable: true,
    value: 'display_name',
  },
  {
    text: consoleI18n.t('devices.labels.deviceId'),
    divider: true,
    sortable: true,
    value: 'id',
  },
  {
    text: consoleI18n.t('devices.labels.disconnectTime'),
    divider: true,
    sortable: true,
    value: 'last_disconnect_time',
    width: '14rem',
  },
  {
    text: consoleI18n.t('devices.labels.disconnectedFor'),
    divider: true,
    sortable: true,
    value: 'disconnected_for',
  },
  {
    text: consoleI18n.t('devices.labels.lastLoggedInUser'),
    value: 'last_logged_in_user',
    sortable: true,
    divider: true,
  },
  {
    text: consoleI18n.t('general.labels.tags'),
    divider: true,
    sortable: true,
    value: 'tags',
  },
  {
    text: consoleI18n.t('general.labels.ipAddress'),
    divider: true,
    sortable: false,
    value: 'ip_addrs',
  },
  {
    text: consoleI18n.t('devices.labels.activeDirectoryOu'),
    divider: true,
    sortable: false,
    value: 'organizational_unit',
  },
  {
    text: consoleI18n.t('general.labels.os'),
    divider: true,
    sortable: true,
    value: 'os_family',
    width: '72.875px',
  },
  {
    text: consoleI18n.t('general.labels.osVersion'),
    divider: true,
    sortable: true,
    value: 'os_version',
  },
  {
    text: consoleI18n.t('devices.labels.agentVersion'),
    divider: true,
    sortable: false,
    value: 'agent_version',
  },
];

interface ZoneDevicesTableHeader extends DataTableHeader<ZoneDeviceTableRow> {
  value: keyof ZoneDeviceTableRow;
}

export const zoneDevicesTableHeaders: ZoneDevicesTableHeader[] = [
  ...baseDevicesTableHeaders,
  {
    text: consoleI18n.t('general.labels.needsAttention'),
    divider: true,
    sortable: true,
    value: 'is_compatible',
  },
  {
    text: consoleI18n.t('general.labels.group'),
    divider: true,
    sortable: true,
    value: 'server_group_id',
  },
  {
    text: consoleI18n.t('devices.labels.totalPatches'),
    divider: true,
    sortable: true,
    value: 'patches',
  },
  {
    text: consoleI18n.t('devices.labels.scheduledPatches'),
    divider: true,
    sortable: true,
    value: 'pending_patches',
  },
  {
    text: consoleI18n.t('general.labels.status'),
    divider: true,
    sortable: false,
    value: 'status',
  },
  {
    text: consoleI18n.t('general.labels.actions'),
    sortable: false,
    value: 'actions',
  },
];

export const globalViewDevicesTableHeaders: DevicesTableHeaderBase[] = [
  {
    text: consoleI18n.t('general.labels.zone'),
    divider: true,
    sortable: true,
    value: 'organization_id',
  },
  ...baseDevicesTableHeaders,
  {
    text: consoleI18n.t('devices.labels.connectionStatus'),
    divider: true,
    sortable: true,
    value: 'connected',
  },
];
