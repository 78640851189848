import * as N from '@ax/function-utils/nullable';
import { constant } from '@ax/function-utils/core';
import { PermissionMap } from '@ax/data-services-authorization/business-logic/permissions';
import { usePermissionsForUserAndOrg } from '@ax/data-services-authorization/compositions';
import * as MacOSDataService from '@ax/data-services-mac-os';
import { User } from '@ax/data-services-user';
import * as R from '@ax/vue-utils/ref';
import { Ref } from '@vue/composition-api';
import { EnrollmentStatus } from '../models';

export interface UseMDMProps {
  readonly orgId: Ref<number>;
  readonly user: Ref<User>;
}

const getEnrollmentStatus = N.fold(
  constant(EnrollmentStatus.unknown),
  constant(EnrollmentStatus.complete),
);

function getPatchStatus(enrollmentStatus: EnrollmentStatus) {
  return enrollmentStatus === EnrollmentStatus.complete
    ? EnrollmentStatus.unlocked
    : EnrollmentStatus.locked;
}

export function useMDMAccount(props: UseMDMProps) {
  const { orgId, user } = props;

  const { data: account, ...helpers } =
    MacOSDataService.Queries.useGetMDMAccountByOrgId(
      R.map_(orgId, MacOSDataService.Queries.GetMDMAccountInfoByOrgId.make),
    );

  const apnsStatus = R.map_(account, getEnrollmentStatus);

  const patchStatus = R.map_(apnsStatus, getPatchStatus);

  const { useCanAccess } = usePermissionsForUserAndOrg(orgId, user);

  const canEditAccountSettings = useCanAccess(
    PermissionMap.Organization.Manage,
  );

  return {
    account,
    apnsStatus,
    patchStatus,
    canEditAccountSettings,
    ...helpers,
  };
}
