var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-table',_vm._g({attrs:{"value":_vm.value,"footer-props":_vm.defaultDevicesTableFooter,"headers":_vm.headers,"active-headers":_vm.activeHeaders,"items":_vm.devices,"options":_vm.tableStateLocal,"server-items-length":_vm.totalDevices,"show-select":_vm.showSelect,"item-key":"id","data-test-id":"devices-page-table","no-data-text":"No devices"},on:{"update:options":function($event){_vm.tableStateLocal=$event}},scopedSlots:_vm._u([{key:"actions-left",fn:function(){return [_c('div',{staticClass:"d-flex justify-between items-center"},[_vm._t("bulk-actions-menu"),_c('ax-combobox',{staticClass:"mb-0",attrs:{"value":_vm.queryState.q,"label":"Search Devices","prepend-inner-icon":_vm.mdiMagnify,"data-test-id":"devices-page-search-bar","chips":"","multiple":"","clearable":"","hide-details":"","append-icon":null},on:{"change":function (q) { return _vm.$emit('query-state-updated', { q: q }); }}})],2)]},proxy:true},{key:"item.display_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"tw-inline-block tw-leading-tight",attrs:{"title":item.display_name,"to":{
        name: 'deviceDetails',
        query: { o: item.organization_id },
        params: { deviceId: item.id },
      },"data-test-id":"device-column-text"}},[_vm._v(" "+_vm._s(item.display_name && item.display_name.length > 32 ? item.display_name.slice(0, 32) + '...' : item.display_name)+" ")])]}},{key:"item.last_disconnect_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_disconnect_time_formatted || _vm.formatUTCToZonedTime(item.last_disconnect_time))+" ")]}},{key:"item.disconnected_for",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.disconnected_for || _vm.formatRelativeDisconnect(item.last_disconnect_time))+" ")]}},{key:"item.tags",fn:function(ref){
      var item = ref.item;
return [_c('v-lazy',[_c('ax-device-tags',{attrs:{"data-test-id":"device-tags","tags":item.tags},on:{"click:tag":function (tag) { return _vm.$emit('tag-clicked', tag); }}})],1)]}},{key:"item.ip_addrs",fn:function(ref){
      var item = ref.item;
return [_c('v-lazy',[_c('ax-ip-address-column',{attrs:{"ips":{
          publicAddresses: item.ip_addrs,
          privateAddresses: item.ip_addrs_private,
        }}})],1)]}},{key:"item.organizational_unit",fn:function(ref){
        var item = ref.item;
return [_c('v-lazy',[_c('ax-device-organizational-unit',{attrs:{"org-unit":item.organizational_unit}})],1)]}},{key:"item.os_family",fn:function(ref){
        var item = ref.item;
return [_c('v-lazy',{staticClass:"os-family-min-height d-flex justify-center align-center"},[_c('ax-os-icon',{attrs:{"os-family":item.os_family}})],1)]}},{key:"item.os_version",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.getOSNameVersion(item)))]}},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }